<template>
  <nav class="tap-bar" data-t="tap-bar">
    <TapBarItem
      icon="casino"
      :title="t('tapBar.casino')"
      link="/casino"
      :is-active="startsWith(route.path, '/casino')"
      data-t="casino"
    />
    <TapBarItem
      icon="soccer"
      :title="t('tapBar.sport')"
      link="/sport"
      :is-active="startsWith(route.path, '/sport')"
      data-t="sport"
    />
    <TapBarItem
      icon="bet"
      link="?modal=bets"
      data-t="bets"
      :title="t('tapBar.myBets')"
      :counter="betCounter"
    />
    <TapBarItem
      icon="file-check-alt"
      :title="t('quests.menuItem')"
      link="/quests"
      :is-active="startsWith(route.path, '/quests')"
      data-t="quests"
      :live-indicator="hasClaimableRewards"
    />
    <TapBarItem
      v-if="isAuthenticated"
      :avatar="user?.avatarUrl"
      :title="t('tapBar.profile')"
      link="/personal/profile"
      :counter="activeNotificationsCounter + bonusesCount"
      :is-active="exactlyEquals(route.path, '/personal/profile')"
      data-t="profile"
    />
    <TapBarItem
      v-else
      icon="login"
      :title="t('tapBar.login')"
      link="?modal=login"
      :is-active="false"
      data-t="login"
    />
  </nav>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useNotificationsStore } from '@st/profile/stores/useNotificationsStore'
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useQuestsStore } from '@st/quests/stores/useQuestsStore'
import TapBarItem from './parts/TapBarItem.vue'

const { t } = useI18n()
const { isAuthenticated, user } = storeToRefs(useUserStore())
const { bonusesCount } = storeToRefs(useBonusesCountStore())
const { activeNotificationsCounter } = storeToRefs(useNotificationsStore())
const { hasClaimableRewards } = storeToRefs(useQuestsStore())
const { betCounter } = storeToRefs(useActiveBetsStore())

const route = useRoute()
const { startsWith, exactlyEquals } = useRouteMatching()
</script>

<style scoped>
.tap-bar {
  position: sticky;
  z-index: 100;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  padding-bottom: var(--spacing-200);

  background: var(--background-primary);
  backdrop-filter: blur(12px);
  border-top: var(--border-width-light, 0.5px) solid var(--border-primary);
}
</style>
